import { useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import MessagesTable from "../Component/MessagesTable";
import api from "../api/api";
import { Paper, Typography } from "@mui/material";
import { useState } from "react";
import DisplaysDropdown from "../Component/DisplaysDropdown";
import { useLocation } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

function Messages() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const initialDisplayId = state ? state.displayIds[0] : "";

  const [displayId, setDisplayId] = useState(initialDisplayId);
  const [isEnabled, setIsEnabled] = useState(false);

  const {
    data: allMessages,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["message", displayId],
    () => api.getAllMessagesForDisplayAndUser(displayId),
    { enabled: isEnabled }
  );

  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        autoHideDuration={3000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }

  return (
    <Paper>
      <Box sx={{ marginTop: 2 }}>
        <DisplaysDropdown
          displayId={displayId}
          setDisplayId={setDisplayId}
          setIsEnabled={setIsEnabled}
          caption={"Choose Blink"}
          queryFunction={() => api.getAllDisplays()}
        />
      </Box>
      {isEnabled && allMessages && (
        <Box sx={{ marginTop: 2, border: 2 }} component={Paper}>
          {allMessages.length > 0 ? (
            <MessagesTable messages={allMessages} displayId={displayId} />
          ) : (
            <Typography variant="h6" textAlign={"center"}>
              No active messages
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default Messages;
