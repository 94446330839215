import { useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import api from "../api/api";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import UsersTable from "../Component/UsersTable";
import AddUser from "../Component/AddUser";
import AdminDisplays from "../Component/AdminDisplays";

function AdminDashboard() {
  const navigate = useNavigate();
  const {
    data: allUsers,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.getAllUsers(),
  });

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        autoHideDuration={3000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="admin dashboard tabs">
            <Tab label="Users" value="1" />
            <Tab label="Add User" value="2" />
            <Tab label="Manage Blinks" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {allUsers.length > 0 ? (
            <UsersTable users={allUsers} />
          ) : (
            <Typography variant="h6" textAlign={"center"}>
              No users
            </Typography>
          )}
        </TabPanel>
        <TabPanel value="2">
          <AddUser />
        </TabPanel>
        <TabPanel value="3">
          <AdminDisplays/>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default AdminDashboard;
