import useAuth from "../hook/useAuth";
import useUser from "../hook/useUser";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import api from "../api/api";
import { Box, Button, FormControlLabel, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { setToken } = useAuth();
  const { setUser } = useUser();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const loginMutation = useMutation({
    mutationFn: (loginMessage) => api.login(loginMessage),
    onSuccess: (data) => {
      console.log("token in response = " + data.token);
      setUser(data.user);
      setToken(data.token);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginMutation.mutate({name, password}, {
      onSuccess: () => {
        setName("");
        setPassword("");
        // navigate(from, { replace: true });
        navigate('/displays');
      },
      onError: (error) => {
        console.log("no result data.  Error = " + error.data);
        setError(error?.message);
      }
    })
  };

  setToken("");
  setUser(null);
  queryClient.clear();

  return (
    
    <div className="App">
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
</Box> */}
      <form onSubmit={handleSubmit}>
        <p>{error}</p>
        <h1>Sign In</h1>
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button disabled={!name && !password ? true : false}>Login</button>
      </form>
    </div>
  );
};

export default Login;
