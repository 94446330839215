import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hook/useAuth";

export const ProtectedRoute = () => {
    const { token } = useAuth();

    console.log("ProtectedRoute token = " + token );

    // Check is the user is authenticated
    if (!token) {
        // If not authenticated, return to login page
        console.log("ProtectedRoute no token");
        return <Navigate to="/" />
    }

    // If authenticated, render the child routes
    return (
            <Outlet />
    );
};