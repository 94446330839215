import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import api from "../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";

export default function RegisterDisplay() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const registerBlinkMutation = useMutation({
    mutationFn: (blink) => api.registerBlink(blink),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["blink"], { exact: true });
      queryClient.setQueryData(["blink", data.id], data);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    
    const data = Object.fromEntries(formData);
    console.log( JSON.stringify(data));
    registerBlinkMutation.mutate(data, {
      onSuccess: navigate('/displays'),
      onError: navigate('.'),
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginBottom: 2,}}>
        Enter the code shown on your new Blink.
      </Typography>
              <TextField
                required
                fullWidth
                name="registrationCode"
                label="Enter registration code"
                id="registrationCode"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginBottom: 2,}}>
        Give your Blink a unique name.  This name will be used by other Blink-ers to send you messages, so 
        it should make it clear to whom this Blink belongs. 
      </Typography>
              <TextField
                required
                fullWidth
                name="displayName"
                label="Public name for this Blink"
                id="displayName"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register Blink
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
