import api from "../api/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

export default function Message() {
  const { id } = useParams();
  console.log("Message from useParams id = " + id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deleteMessageMutation = useMutation({
    mutationFn: (id) => api.deleteMessage(id),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["message"], { exact: true });
      queryClient.invalidateQueries(["message", variables.id], { exact: true });
    },
  });

  const { data, isLoading, isError, error } = useQuery({
    queryFn: () => api.getMessage(id),
    queryKey: ["message", id],
  });

  if (isLoading) return <h1>Loading...</h1>;

  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        autoHideDuration={5000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }


  return (
    <Container>
      <Typography variant="h5" component={"h4"}>
        {data.author ? <>From: {data.author}</> : <i>No Name</i>}
      </Typography>

      {data.content && 
      <Typography variant="h5" component={"p"}>Message: {data.content}</Typography>}

      <Button
        variant="contained"
        onClick={() => {
          deleteMessageMutation.mutate(data.id, {
            onSuccess: () => {
              navigate("/messages");
            },
          });
        }}
      >
        Delete
      </Button>
    </Container>
  );
}
