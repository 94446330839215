import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import useAuth from "./hook/useAuth";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import Login from "./mainPages/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Messages from "./mainPages/Messages";
import Configuration from "./mainPages/Configuration";
import Message from "./mainPages/Message";
import SendMessage from "./mainPages/SendMessage";
import Home from "./mainPages/Home";
import UserHome from "./mainPages/UserHome";
import Profile from "./mainPages/Profile";
import Layout from "./Layout/Layout";
import AdminDashboard from "./mainPages/AdminDashboard";
import RegisterDisplay from "./mainPages/RegisterDisplay";
import Displays from "./mainPages/Displays";

const queryClient = new QueryClient();

const App = () => {
  const { token } = useAuth();

  const routesForPublic = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      path: "/about-us",
      element: (
        <div>
          <h1>About Us</h1>
          <Link to="/messages">Messages</Link>
        </div>
      ),
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      errorElement: <Login />,
      children: [
        {
          path: "/",
          element: <UserHome />,
        },
        {
          path: "/messages",
          element: <Messages />,
        },
        {
          path: "/configuration",
          element: <Configuration />,
        },
        {
          path: "/displays",
          element: <Displays />,
        },
        {
          path: "/message/:id",
          element: <Message />,
        },
        {
          path: "/sendMessage",
          element: <SendMessage />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/admin",
          element: <AdminDashboard />,
        },
        {
          path: "/registerDisplay",
          element: <RegisterDisplay />,
        },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Home />,
    },
  ];

  const layoutRoute = [
    {
      path: "/",
      element: <Layout />,
      children: [
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
      ],
    },
  ];

  console.log("App Token = " + token);
  const router = createBrowserRouter([...layoutRoute]);
  console.log("After createBrowserRouter");

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
