
const UserHome = () => {

  return (
    <main>
      <h1>You are logged in!</h1>
    </main>
  );
};

export default UserHome;
