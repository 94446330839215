import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import api from "../api/api";
import DisplaysDropdown from "../Component/DisplaysDropdown";
import dayjs from "dayjs";
import { Slider } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const screens = ["clock", "message"];
const fontFamilies = [
  "Sans",
  "SansBold",
  "Serif",
  "SerifBold",
  "Mono",
  "MonoBold",
];
const scrollSpeeds = ["fast", "medium", "slow"];
const splashScreens = [
  { value: "none", title: "None" },
  { value: "image", title: "Image" },
  { value: "randomdot", title: "Random Dots" },
  { value: "squares", title: "Squares" },
  { value: "stripes", title: "Stripes" },
  { value: "text", title: "Text" },
];
const colors = [
  "blue",
  "lightBlue",
  "teal",
  "green",
  "yellow",
  "orange",
  "red",
  "purple",
  "pink",
  "magenta",
  "white",
];
const priorities = ["FUN", "NORMAL", "IMPORTANT", "URGENT"];

function Configuration() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [configMsg, setConfigMsg] = useState(null);
  const [onOffConfigs, setOnOffConfigs] = useState([
    {
      activationTime: dayjs(),
      isOn: true,
      wakeOnUrgent: false,
      minDisplayPriority: "FUN",
      brightness: 8,
      noMovementSleepDelayMin: 120,
    },
    {
      activationTime: dayjs(),
      isOn: true,
      wakeOnUrgent: false,
      minDisplayPriority: "FUN",
      brightness: 8,
      noMovementSleepDelayMin: 120,
    },
  ]);

  // useState({
  //   screenDurationSec: 20,
  //   messageDisplaySec: 8,
  //   scrollSpeed: "medium",
  //   splashScreenSec: 5,
  //   fontFamily: "Sans",
  //   screens: ["message", "clock"],
  //   displayUser: true,
  //   minUserDisplaySec: 2,
  //   messageColor: ["green", "blue", "magenta", "red"],
  //   messageSplashScreens: ["none", "none", "none", "none"],
  //   messageSplashScreenText: [
  //     "Fun Messages",
  //     "Normal Messages",
  //     "Important Messages",
  //     "Urgent Messages",
  //   ],
  //   minutesFromUTC: -300,
  //   timeColor: "green",
  //   dateColor: "red",
  //   alertColor: ["green", "blue", "magenta", "red"],
  //   reminderColor: ["green", "blue", "magenta", "red"],
  //   splashScreens: [],
  //   onOffConfigs: [
  //     {
  //       activationTime: 0,
  //       isOn: true,
  //       wakeOnUrgent: false,
  //       minDisplayPriority: "FUN",
  //       brightness: 8,
  //       noMovementSleepDelayMin: 120,
  //     },
  //   ],
  // });
  const [displayId, setDisplayId] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  var objectSupport = require("dayjs/plugin/objectSupport");
  dayjs.extend(objectSupport);
  const dayjsToMinutesFromMidnight = (d) => 60 * d.hour() + d.minute();
  const compareActivationTimes = (a, b) => {
    const aMin = dayjsToMinutesFromMidnight(a.activationTime);
    const bMin = dayjsToMinutesFromMidnight(b.activationTime);
    return aMin - bMin;
  };

  const handleChange = (evt) => {
    var { name, value } = evt.target;
    if (
      (name.localeCompare("screenDurationSec") === 0 ||
        name.localeCompare("messageDisplaySec") === 0 ||
        name.localeCompare("splashScreenSec") === 0) &&
      (typeof value === "string")
    ) {
      value = Number(value);
    }
    setConfigMsg({ ...configMsg, [name]: value });
  };

  const handleChangeScreens = (evt) => {
    setConfigMsg({
      ...configMsg,
      screens:
        typeof evt.target.value === "string"
          ? evt.target.value.split(",")
          : evt.target.value,
    });
  };
  const handleChangeDisplayUser = (evt) => {
    setConfigMsg({ ...configMsg, displayUser: evt.target.checked });
  };
  const handleChangeMessageSplashScreen = (index, evt) => {
    let splashScreens = [...configMsg.messageSplashScreens];
    splashScreens[index] = evt.target.value;
    setConfigMsg({ ...configMsg, messageSplashScreens: splashScreens });
  };
  const handleChangeMessageSplashScreenText = (index, name, evt) => {
    let splashScreenText = [...configMsg.messageSplashScreenText];
    splashScreenText[index] = evt.target.value;
    setConfigMsg({ ...configMsg, messageSplashScreenText: splashScreenText });
  };

  var onOffConfigChanged = false;
  const handleChangeOnOffConfigTime = (index, name, ts) => {
    console.log(
      "handleChangeOnOffConfigTime index = " +
        index +
        ", name = " +
        name +
        ", ts = " +
        JSON.stringify(ts)
    );
    const config = { ...onOffConfigs[index] };
    config[name] = ts;
    const configs = [...onOffConfigs];
    configs[index] = config;
    console.log(
      "handleChangeOnOffConfigTime configs = " + JSON.stringify(configs)
    );
    onOffConfigChanged = true;
    setOnOffConfigs(configs);
  };
  const handleChangeOnOffConfigChecked = (index, name, evt) => {
    console.log(
      "handleChangeOnOffConfigChecked index = " +
        index +
        ", name = " +
        name +
        ", checked = " +
        evt.target.checked
    );
    const config = { ...onOffConfigs[index] };
    config[name] = evt.target.checked;
    const configs = [...onOffConfigs];
    configs[index] = config;
    console.log(
      "handleChangeOnOffConfigChecked configs = " + JSON.stringify(configs)
    );
    onOffConfigChanged = true;
    setOnOffConfigs(configs);
  };
  const handleChangeOnOffConfig = (index, name, evt) => {
    console.log(
      "handleChangeOnOffConfig index = " +
        index +
        ", name = " +
        name +
        ", value = " +
        evt.target.value
    );
    const config = { ...onOffConfigs[index] };
    config[name] = evt.target.value;
    const configs = [...onOffConfigs];
    configs[index] = config;
    console.log("handleChangeOnOffConfig configs = " + JSON.stringify(configs));
    onOffConfigChanged = true;
    setOnOffConfigs(configs);
  };
  const handleDeleteOnOffConfig = (index) => {
    onOffConfigChanged = true;
    if (index === 0) {
      setOnOffConfigs(onOffConfigs.slice(1));
    } else if (index === onOffConfigs.length - 1) {
      setOnOffConfigs(onOffConfigs.slice(0, index));
    } else {
      setOnOffConfigs(
        onOffConfigs.slice(0, index).concat(onOffConfigs.slice(index + 1))
      );
    }
  };
  const handleAddOnOffConfig = () => {
    onOffConfigChanged = true;
    setOnOffConfigs(
      [
        ...onOffConfigs,
        {
          activationTime: dayjs({ hour: 0, minute: 0 }),
          isOn: true,
          wakeOnUrgent: false,
          minDisplayPriority: "FUN",
          brightness: 8,
          noMovementSleepDelayMin: 120,
        },
      ].toSorted(compareActivationTimes)
    );
  };

  const { data, isLoading, isError, error } = useQuery(
    ["blinkconfig", displayId],
    () => api.getBlinkConfig(displayId),
    {
      enabled: isEnabled,
      onSuccess: (data) => {
        setOnOffConfigs(
          data.onOffConfigs
            .map((cfg) => {
              const newActivationTime = dayjs({
                hour: Math.floor(cfg.activationTime / 60),
                minute: cfg.activationTime % 60,
              });
              return { ...cfg, activationTime: newActivationTime };
            })
            .toSorted(compareActivationTimes)
        );
        setConfigMsg({ ...data });
      },
    }
  );

  const setConfigMutation = useMutation({
    mutationFn: (message) => {
      api.setBlinkConfig(displayId, message);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["blinkconfig", displayId], data);
    },
  });

  const patchConfigMutation = useMutation({
    mutationFn: (message) => {
      api.patchBlinkConfig(displayId, message);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["blinkconfig", displayId], data);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Sort the onOffConfigs
    setOnOffConfigs(onOffConfigs.toSorted(compareActivationTimes));
    const newOnOffConfigs = onOffConfigs.map((cfg) => {
      // Convert dayjs to minutes from midnight
      return {
        ...cfg,
        activationTime: dayjsToMinutesFromMidnight(cfg.activationTime),
      };
    });
    configMsg.timestamp = dayjs().unix();

    const newConfigMsg = { ...configMsg };
    newConfigMsg.onOffConfigs = newOnOffConfigs;
    setConfigMsg(newConfigMsg);
    setConfigMutation.mutate(newConfigMsg, {
      onError: (error) => {
        console.log("Error sending message.  Error = " + error.data);
        setErrorMsg(error?.message);
      },
    });
  };

  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        autoHideDuration={3000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 2 }}>
        <DisplaysDropdown
          displayId={displayId}
          setDisplayId={setDisplayId}
          setIsEnabled={setIsEnabled}
          caption={"Choose Blink"}
          queryFunction={() => api.getDisplaysForMe()}
        />
        {isLoading || !configMsg ? (
          <p></p>
        ) : !isEnabled ? (
          <p>There are no registered displays</p>
        ) : (
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <TextField
                required
                fullWidth
                name="screenDurationSec"
                label="Seconds to display each screen"
                id="screenDurationSec"
                value={configMsg.screenDurationSec}
                onChange={handleChange}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                sx={{ mt: 3 }}
              />
              <TextField
                required
                fullWidth
                name="messageDisplaySec"
                label="Minumum seconds to display each message"
                id="messageDisplaySec"
                value={configMsg.messageDisplaySec}
                onChange={handleChange}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                sx={{ mt: 3 }}
              />
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="scrollSpeed-select-label">
                  Scroll Speed
                </InputLabel>
                <Select
                  labelId="scrollSpeed-select-label"
                  name="scrollSpeed"
                  id="scrollSpeed-select"
                  value={configMsg.scrollSpeed}
                  label="Scroll Speed"
                  onChange={handleChange}
                >
                  {scrollSpeeds.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                name="splashScreenSec"
                label="Seconds to display each splash screen"
                id="splashScreenSec"
                value={configMsg.splashScreenSec}
                onChange={handleChange}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                sx={{ mt: 3 }}
              />
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="fontFamily-select-label">
                  Font Family
                </InputLabel>
                <Select
                  labelId="fontFamily-select-label"
                  id="fontFamily-select"
                  name="fontFamily"
                  value={configMsg.fontFamily}
                  label="Font Family"
                  onChange={handleChange}
                >
                  {fontFamilies.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="screens-label">Screens</InputLabel>
                <Select
                  labelId="screens-label"
                  id="screens"
                  name="screens"
                  multiple
                  value={configMsg.screens}
                  onChange={handleChangeScreens}
                  renderValue={(selected) => selected.join(", ")}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {screens.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={configMsg.screens.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                fullWidth
                sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
              >
                <FormControl>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={configMsg.displayUser}
                        onChange={handleChangeDisplayUser}
                        name="displayUser"
                      />
                    }
                    label='Display "User says" screen for messages?'
                  />
                </FormControl>
                {configMsg.displayUser && (
                  <TextField
                    required
                    fullWidth
                    name="minUserDisplaySec"
                    label='Seconds to display "User Says:" screen'
                    id="minUserDisplaySec"
                    value={configMsg.minUserDisplaySec}
                    onChange={handleChange}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    sx={{ mt: 3 }}
                  />
                )}
              </Box>
              <FormControl
                fullWidth
                sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
              >
                <InputLabel id="splashScreen-fun-select-label">
                  Splash screen for Fun messages
                </InputLabel>
                <Select
                  labelId="splashScreen-fun-select-label"
                  id="splashScreen-fun-select"
                  value={configMsg.messageSplashScreens[0]}
                  label="Splash screen for Fun messages"
                  onChange={(e) => {
                    handleChangeMessageSplashScreen(0, e);
                  }}
                >
                  {splashScreens.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
                {configMsg.messageSplashScreens[0] === "text" && (
                  <TextField
                    required
                    fullWidth
                    name="messageSplashScreenTextFun"
                    label="Fun message splash screen text"
                    id="messageSplashScreenTextFun"
                    value={configMsg.messageSplashScreenText[0]}
                    onChange={(e) => {
                      handleChangeMessageSplashScreenText(0, e);
                    }}
                    sx={{ mt: 3 }}
                  />
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
              >
                <InputLabel id="splashScreen-normal-select-label">
                  Splash screen for Normal messages
                </InputLabel>
                <Select
                  labelId="splashScreen-normal-select-label"
                  id="splashScreen-normal-select"
                  value={configMsg.messageSplashScreens[1]}
                  label="Splash screen for Normal messages"
                  onChange={(e) => {
                    handleChangeMessageSplashScreen(1, e);
                  }}
                >
                  {splashScreens.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
                {configMsg.messageSplashScreens[1] === "text" && (
                  <TextField
                    required
                    fullWidth
                    name="messageSplashScreenTextNormal"
                    label="Normal message splash screen text"
                    id="messageSplashScreenTextNormal"
                    value={configMsg.messageSplashScreenText[1]}
                    onChange={(e) => {
                      handleChangeMessageSplashScreenText(1, e);
                    }}
                    sx={{ mt: 3 }}
                  />
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
              >
                <InputLabel id="splashScreen-important-select-label">
                  Splash screen for Important messages
                </InputLabel>
                <Select
                  labelId="splashScreen-important-select-label"
                  id="splashScreen-important-select"
                  value={configMsg.messageSplashScreens[2]}
                  label="Splash screen for Important messages"
                  onChange={(e) => {
                    handleChangeMessageSplashScreen(2, e);
                  }}
                >
                  {splashScreens.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
                {configMsg.messageSplashScreens[2] === "text" && (
                  <TextField
                    required
                    fullWidth
                    name="messageSplashScreenTextImportant"
                    label="Important message splash screen text"
                    id="messageSplashScreenTextImportant"
                    value={configMsg.messageSplashScreenText[2]}
                    onChange={(e) => {
                      handleChangeMessageSplashScreenText(2, e);
                    }}
                    sx={{ mt: 3 }}
                  />
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
              >
                <InputLabel id="splashScreen-urgent-select-label">
                  Splash screen for Urgent messages
                </InputLabel>
                <Select
                  labelId="splashScreen-urgent-select-label"
                  id="splashScreen-urgent-select"
                  value={configMsg.messageSplashScreens[3]}
                  label="Splash screen for Urgent messages"
                  onChange={(e) => {
                    handleChangeMessageSplashScreen(3, e);
                  }}
                >
                  {splashScreens.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
                {configMsg.messageSplashScreens[3] === "text" && (
                  <TextField
                    required
                    fullWidth
                    name="messageSplashScreenTextUrgent"
                    label="Urgent message splash screen text"
                    id="messageSplashScreenTextUrgent"
                    value={configMsg.messageSplashScreenText[3]}
                    onChange={(e) => {
                      handleChangeMessageSplashScreenText(3, e);
                    }}
                    sx={{ mt: 3 }}
                  />
                )}
              </FormControl>
              <TextField
                required
                fullWidth
                name="minutesFromUTC"
                label="Minutes from UTC"
                id="minutesFromUTC"
                value={configMsg.minutesFromUTC}
                onChange={handleChange}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                sx={{ mt: 3 }}
              />
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="timeColor-select-label">
                  Color for Time Display
                </InputLabel>
                <Select
                  labelId="timeColor-select-label"
                  name="timeColor"
                  id="timeColor-select"
                  value={configMsg.timeColor}
                  label="Color for Time Display"
                  onChange={handleChange}
                >
                  {colors.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="dateColor-select-label">
                  Color for Date Display
                </InputLabel>
                <Select
                  labelId="dateColor-select-label"
                  name="dateColor"
                  id="dateColor-select"
                  value={configMsg.dateColor}
                  label="Color for Date Display"
                  onChange={handleChange}
                >
                  {colors.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Paper
                elevation={2}
                fullWidth
                sx={{ mt: 3, border: "2px solid grey", borderRadius: 1 }}
              >
                <Typography variant="body1" display="block" gutterBottom>
                  {"On/Off Configuration"}
                </Typography>
                <Tooltip title={"Add On-Off Interval"}>
                  <AddIcon
                    onClick={() => {
                      handleAddOnOffConfig();
                    }}
                  />
                </Tooltip>
                {onOffConfigs.map((config, index) => (
                  <Paper
                    elevation={1}
                    fullWidth
                    sx={{
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      mb: 1,
                      border: "2px solid lightgrey",
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="caption" display="block" gutterBottom>
                      {"On/Off Interval " + index}
                    </Typography>
                    <Tooltip title={"Delete On-Off Interval" + index}>
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteOnOffConfig(index);
                        }}
                      />
                    </Tooltip>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Activation time for this configuration"
                        name="onOffConfigs[index].activationTime"
                        value={onOffConfigs[index].activationTime}
                        onChange={(ts) =>
                          handleChangeOnOffConfigTime(
                            index,
                            "activationTime",
                            ts
                          )
                        }
                      />
                    </LocalizationProvider>
                    <FormControl>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={onOffConfigs[index].isOn}
                            onChange={(e) =>
                              handleChangeOnOffConfigChecked(index, "isOn", e)
                            }
                            name="isOn"
                          />
                        }
                        label="Display on during this time period?"
                      />
                    </FormControl>
                    {onOffConfigs[index].isOn && (
                      <Box>
                        <Box fullWidth sx={{ mt: 3, ml: 1, mr: 1 }}>
                          <Typography id="input-slider" gutterBottom>
                            Display Brightness
                          </Typography>
                          <Slider
                            aria-labelledby="input-slider"
                            value={
                              typeof onOffConfigs[index].brightness === "number"
                                ? onOffConfigs[index].brightness
                                : 0
                            }
                            onChange={(e) =>
                              handleChangeOnOffConfig(index, "brightness", e)
                            }
                            valueLabelDisplay="on"
                            step={1}
                            marks
                            min={1}
                            max={10}
                          />
                        </Box>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                          <InputLabel id="minDisplayPriority-select-label">
                            Lowest message priority to display
                          </InputLabel>
                          <Select
                            labelId="minDisplayPriority-select-label"
                            name="minDisplayPriority"
                            id="minDisplayPriority-select"
                            value={onOffConfigs[index].minDisplayPriority}
                            label="Lowest message priority to display"
                            onChange={(e) =>
                              handleChangeOnOffConfig(
                                index,
                                "minDisplayPriority",
                                e
                              )
                            }
                          >
                            {priorities.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name.charAt(0) + name.slice(1).toLowerCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {!onOffConfigs[index].isOn && (
                      <FormControl>
                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Checkbox
                              checked={onOffConfigs[index].wakeOnUrgent}
                              onChange={(e) =>
                                handleChangeOnOffConfigChecked(
                                  index,
                                  "wakeOnUrgent",
                                  e
                                )
                              }
                              name="wakeOnUrgent"
                            />
                          }
                          label="Wake for urgent messages?"
                        />
                      </FormControl>
                    )}
                  </Paper>
                ))}
              </Paper>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Configuration
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Configuration;
