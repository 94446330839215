import { Link } from "react-router-dom";
import useUser from "../hook/useUser";

const Profile = () => {
  const { user } = useUser();
  return (
    <main>
      <h2>User Profile</h2>
      <ul>
      <li>{user.id}</li>
      <li>{user.username}</li>
      <li>{user.firstname}</li>
      <li>{user.lastname}</li>
      <li>{user.email}</li>
      </ul>
<div>{JSON.stringify(user)}</div>
    </main>
  );
};

export default Profile;
