import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import useAuth from "../hook/useAuth";
import useUser from "../hook/useUser";
import { Link as RouterLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const UserMenu = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { setToken } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const queryClient = useQueryClient();

  const onLogout = (evt) => {
    evt.preventDefault();
    setToken("");
    setUser(null);
    queryClient.clear();
    navigate("/", { replace: true });
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar
          alt={user ? `${user.firstname} ${user.lastname}` : "Unknown"}
          src="/static/images/avatar/2.jpg"
        />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>
          <Link component={RouterLink} textAlign="center" to="/profile">
            Profile
          </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu}>
          <Link component={RouterLink} textAlign="center" to="/admin">
            Admin Dashboard
          </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu}>
          <Typography textAlign="center" onClick={onLogout}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
