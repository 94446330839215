import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, Link as RouterLink } from "react-router-dom";

const LoginButton = (props) => {
  const navigate = useNavigate();

  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button variant="contained" component={RouterLink} to="/login" >
        Login
      </Button>
    </Box>
  );
};

export default LoginButton;
