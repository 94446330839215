import {
    createContext,
    useMemo,
    useEffect,
    useState,
} from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem("user"));

    useEffect(() => {
        if (user) {
            console.log("UserProvider::useEffect user set to " + user.username);
            localStorage.setItem('user', user);
        } else {
            console.log("UserProvider::useEffect user cleared");
            localStorage.removeItem('user');
        }
        return (() => console.log("UserProvider::useEffect cleanup"))
    }, [user]);

    const contextValue = useMemo(
        () => ({
            user,
            setUser,
        }), [user]
    );

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider,  UserContext };