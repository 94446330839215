import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import api from "../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Typography } from "@mui/material";

const MessagesTable = ({messages, displayId}) => {
  const queryClient = useQueryClient();

  const deleteMessageMutation = useMutation({
    mutationFn: (id) => api.deleteMessage(id),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["message"], { exact: true })
      queryClient.invalidateQueries(["message", displayId], { exact: true })
    }
  });

  return (
    <TableContainer>
    <Table aria-label="Messages table">
      <caption style={{"captionSide": "top", "textalign" : "center"}}>
        <Typography variant="subtitle1">
        Active Messages
        </Typography>
      </caption>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell align="right">Message</TableCell>
          <TableCell align="right">Author</TableCell>
          <TableCell align="right">Sent Time</TableCell>
          <TableCell align="right">ExpiryTime</TableCell>
          <TableCell align="right">Priority</TableCell>
          <TableCell align="right">Text Effect</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {messages.map((message) => (
          <TableRow
            key={message.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {message.id}
            </TableCell>
            <TableCell align="right">
              <Link component={RouterLink} to={`/message/${message.id}`}>{message.content}</Link>
            </TableCell>
            <TableCell align="right">{message.author}</TableCell>
            <TableCell align="right">{message.timestamp}</TableCell>
            <TableCell align="right">{message.expirytime}</TableCell>
            <TableCell align="right">{message.priority}</TableCell>
            <TableCell align="right">{message.textEffect}</TableCell>
            <TableCell align="right">
              <Tooltip title="Edit Message for Resending">
                <EditIcon />
              </Tooltip>
              <Tooltip title="Delete message from display">
                <DeleteIcon
                  onClick={() => {
                    deleteMessageMutation.mutate(message.id);
                  }}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
}

export default MessagesTable
