import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import UserMenu from "../Component/UserMenu";
import useAuth from "../hook/useAuth";
import LoginButton from "../Component/LoginButton";

const Header = () => {
  const { token } = useAuth();

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <header>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link
              variant="h5"
              component={RouterLink}
              to="/"
              sx={{
                color: "inherit",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            >
              <img
                src="images/welcome-nobackground-small.png"
                alt="RoseGoose Design"
                style={{ width: "40px" }}
              />
            </Link>
            <Link
              component={RouterLink}
              variant="h5"
              noWrap
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                color: "inherit",
                textDecoration: "none",
              }}
            >
              RoseGoose
            </Link>
            {token && (
              <>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        textAlign="center"
                        component={RouterLink}
                        to="/sendMessage"
                      >
                        Send Message
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        textAlign="center"
                        component={RouterLink}
                        to="/messages"
                      >
                        View Messages
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        textAlign="center"
                        component={RouterLink}
                        to="/displays"
                      >
                        View Displays
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        textAlign="center"
                        component={RouterLink}
                        to="/configuration"
                      >
                        Configure Display
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        textAlign="center"
                        component={RouterLink}
                        to="/registerDisplay"
                      >
                        Register Display
                      </Link>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Button
                    textAlign="center"
                    sx={{ my: 2, color: "white", display: "block" }}
                    component={RouterLink}
                    to="/sendMessage"
                  >
                    Send Message
                  </Button>
                  <Button
                    textAlign="center"
                    sx={{ my: 2, color: "white", display: "block" }}
                    component={RouterLink}
                    to="/messages"
                  >
                    View Messages
                  </Button>
                  <Button
                    textAlign="center"
                    sx={{ my: 2, color: "white", display: "block" }}
                    component={RouterLink}
                    to="/displays"
                  >
                    View Displays
                  </Button>
                  <Button
                    textAlign="center"
                    sx={{ my: 2, color: "white", display: "block" }}
                    component={RouterLink}
                    to="/configuration"
                  >
                    Configure Display
                  </Button>
                  <Button
                    textAlign="center"
                    sx={{ my: 2, color: "white", display: "block" }}
                    component={RouterLink}
                    to="/registerDisplay"
                  >
                    Register Display
                  </Button>
                </Box>
              </>
            )}
            <Link
              component={RouterLink}
              to="/"
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            >
              <img
                src="images/welcome-nobackground-small.png"
                alt="RoseGoose Design"
                style={{ width: "40px" }}
              />
            </Link>
            <Link
              component={RouterLink}
              variant="h5"
              noWrap
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              RoseGoose Design
            </Link>
            {token ? <UserMenu /> : <LoginButton />}
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
};

export default Header;
