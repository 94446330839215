import Box from "@mui/material/Box"
import Copyright from "./Copyright"

const Footer = () => {
  return (
    <Box>
    <footer>
      <Copyright />
    </footer>
    </Box>
  )
}

export default Footer
