import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import { Tooltip, Typography } from "@mui/material";
import api from "../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const AdminDisplays = () => {
    const navigate = useNavigate();
    const {
      data: allDisplays,
      isLoading,
      isError,
      error,
    } = useQuery({
      queryKey: ["display"],
      queryFn: () => api.getAllDisplays(),
      throwOnError: true,
    });
  
    const queryClient = useQueryClient();
  
    const deleteDisplayMutation = useMutation({
      mutationFn: (id) => api.deleteBlink(id),
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["display"], { exact: true });
        queryClient.invalidateQueries(["display", variables.id], { exact: true });
      },
    });
    
    if (isLoading) return <p>Loading...</p>;
    if (isError) {
      return (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={3000}
          onClose={() => navigate("/login", { replace: true })}
          message="Login expired.  Please log in again."
        />
      );
    }

    return (
    <TableContainer>
    <Table aria-label="Displays table">
      <caption style={{ captionSide: "top", textalign: "center" }}>
        <Typography variant="subtitle1">Registered Displays</Typography>
      </caption>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Owner</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allDisplays.map((display) => {
          console.log(display);
          return (
            <TableRow
              key={display.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {display.name}
              </TableCell>
              <TableCell align="right">{display.owner}</TableCell>
              <TableCell align="right">{display.status}</TableCell>
              <TableCell align="right">
                <Tooltip title="Delete display">
                  <DeleteIcon
                    onClick={() => {
                      deleteDisplayMutation.mutate(display.id);
                    }}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
};

export default AdminDisplays;
