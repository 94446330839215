import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useQuery } from "@tanstack/react-query";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

export default function DisplaysDropdown({
  displayId,
  setDisplayId,
  setIsEnabled,
  caption,
  queryFunction,
}) {
  const navigate = useNavigate();
  const {
    data: allDisplays,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["display"],
    queryFn: queryFunction,
    onSuccess: (data) => {
      if (displayId && data && data.length > 0) {
        setIsEnabled(true);
      }
    },
  });

  const handleChange = (event) => {
    if (allDisplays && allDisplays.length > 0) {
      setDisplayId(event.target.value);
      setIsEnabled(true);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        autoHideDuration={3000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }
  return (
    <Box maxWidth="xs" sx={{ m: 1, minWidth: 240 }}>
      <FormControl fullWidth>
        <InputLabel id="blink-select-label">{caption}</InputLabel>
        <Select
          labelId="blink-select-label"
          id="blink-select"
          value={displayId}
          label={caption}
          onChange={handleChange}
          autoFocus
        >
          {allDisplays.map((display) => {
            return (
              <MenuItem key={display.id} value={display.id}>
                {display.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
