import {
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import api from "../api/api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        if (token) {
            console.log("AuthProvider::useEffect token set to " + token);
            api.setAuthHeader(token);
            localStorage.setItem('token', token);
        } else {
            console.log("AuthProvider::useEffect token cleared");
            api.clearAuthHeader();
            localStorage.removeItem('token');
        }
        return (() => console.log("AuthProvider::useEffect cleanup"))
    }, [token]);

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }), [token]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider,  AuthContext };