import axios from 'axios';

const currentUrl = new URL(window.location.href)
const apiUrl = `${currentUrl}api`
console.log("currentUrl = " + currentUrl);
console.log("apiUrl = " + apiUrl);

export const axiosClient =  axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    }
});


const api = {
    // Utility methods (not API Calls)
    setAuthHeader: (token) => {
        axiosClient.defaults.headers.common["Authorization"] = "Bearer " + token;
        console.log("Setting Authorization header to Bearer "+ token);
    },
    clearAuthHeader: () => delete axiosClient.defaults.headers.common["Authorization"],

    // Authentication API
    login: ({name, password}) => axiosClient.post('/auth/signin', { username: name, password}).then((res) => res.data),

    // Message API
    getAllMessagesForDisplayAndUser: (id) => axiosClient.get(`textmessage/display/${id}`).then((res) => res.data),
    getMessage: (id) => axiosClient.get(`textmessage/${id}`).then((res) => res.data),
    sendMessage: (message) => {
        axiosClient.post('textmessage/send', message)
    },
    deleteMessage: (id) => axiosClient.delete(`textmessage/delete/${id}`),

    // Blink Configuration API
    getBlinkConfig: (id) => axiosClient.get(`config/blink/${id}`).then((res) => res.data),
    setBlinkConfig: (id, config) => axiosClient.put(`config/blink/${id}`, config).then((res) => res.data),
    patchBlinkConfig: (id, configPatch) => axiosClient.patch(`config/blink/${id}`, configPatch, {
        headers: {
            'Content-Type': 'application/json-patch+json'
        }
    }).then((res) => res.data),

    // Admin-Only User API 
    getAllUsers: () => axiosClient.get('admin/user').then((res) => res.data),
    addUser: (user) => axiosClient.post('admin/user/new', user),
    
    // User API

    // Blink API
    getAllDisplays: () => axiosClient.get('display').then((res) => res.data),
    getDisplaysForMe: () => axiosClient.get('display/me').then((res) => res.data),
    registerBlink: (blink) => axiosClient.post('display', blink),
    deleteBlink: (id) => axiosClient.delete(`display/${id}`),

};

export default api;