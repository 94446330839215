import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@tanstack/react-query";
import api from "../api/api";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DisplaysDropdownMultiple({
  displayIds,
  setDisplayIds,
}) {
  const navigate = useNavigate();
  const {
    data: allDisplays,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["display"],
    queryFn: () => api.getAllDisplays(),
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDisplayIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        autoHideDuration={3000}
        onClose={() => navigate("/login", { replace: true })}
        message="Login expired.  Please log in again."
      />
    );
  }

  return (
    <Box maxWidth="xs" sx={{ m: 1, minWidth: 240 }}>
      <FormControl fullWidth>
        <InputLabel id="displays-multiple-checkbox-label">
          Destination
        </InputLabel>
        <Select
          labelId="displays-multiple-checkbox-label"
          id="displays-multiple-checkbox"
          multiple
          value={displayIds}
          onChange={handleChange}
          input={<OutlinedInput label="Destination" />}
          renderValue={(selected) =>
            selected
              .map((s) => allDisplays.find((d) => d.id === s).name)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {allDisplays.map((display) => (
            <MenuItem key={display.id} value={display.id}>
              <Checkbox checked={displayIds.indexOf(display.id) > -1} />
              <ListItemText primary={display.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
