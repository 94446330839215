import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DisplaysDropdownMultiple from "../Component/DisplaysDropdownMultiple";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function SendMessage() {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [doesExpire, setDoesExpire] = useState(false);
  const [expirytime, setExpiryTime] = useState(dayjs().add(1, "minute"));
  const [priority, setPriority] = useState("Normal");
  const [textEffect, setTextEffect] = useState("NoEffect");
  const [color, setColor] = useState("blue");
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const [displayIds, setDisplayIds] = useState([]);

  const sendMessageMutation = useMutation({
    mutationFn: (message) => api.sendMessage(message),
    onSuccess: () => {
      queryClient.invalidateQueries(["message"], { exact: true });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let tmpExpirytime;
    if (!doesExpire) {
      tmpExpirytime = 0;
    } else {
      console.log("submitting message. expirytime = " + expirytime.toString());
      // unix timestamp in millisec
      tmpExpirytime = expirytime.valueOf();
    }
    sendMessageMutation.mutate(
      {
        content: content,
        displayIds: displayIds,
        expirytime: tmpExpirytime,
        priority: priority,
        textEffect: textEffect,
        color: color,
      },
      {
        onSuccess: () => {
          navigate(`/messages`, { state: { displayIds: displayIds } });
        },
        onError: (error) => {
          console.log("Error sending message.  Error = " + error.data);
          setError(error?.message);
        },
      }
    );
  };

  const handleChangeDoesExpire = (evt) => {
    setDoesExpire(evt.target.checked);
  };
  const OnChangeContent = (evt) => setContent(evt.target.value);
  const OnChangePriority = (evt) => {
    setPriority(evt.target.value);
  };
  const OnChangeTextEffect = (evt) => {
    setTextEffect(evt.target.value);
  };
  const onChangeMessageColor = (evt) => {
    setColor(evt.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container component="main" maxWidth="xs">
        <DisplaysDropdownMultiple
          displayIds={displayIds}
          setDisplayIds={setDisplayIds}
        />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <TextField
              disabled={displayIds.length === 0}
              required
              fullWidth
              name="content"
              label="Enter the message"
              id="content"
              value={content}
              onChange={OnChangeContent}
              autoFocus
              sx={{ mt: 3 }}
            />
            <Box
              fullWidth
              sx={{ mt: 3, border: "2px solid lightgrey", borderRadius: 1 }}
            >
              <FormControl>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      disabled={displayIds.length === 0}
                      checked={doesExpire}
                      onChange={handleChangeDoesExpire}
                      name="displayUser"
                    />
                  }
                  label="Should message expire?"
                />
              </FormControl>
              {doesExpire && (
                <DateTimePicker
                  required
                  fullWidth
                  disabled={displayIds.length === 0}
                  label="Message expiration date/time"
                  id="expirytime"
                  value={expirytime}
                  onChange={(exp) => setExpiryTime(exp)}
                  sx={{ mt: 3 }}
                  />
              )}
            </Box>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="priority-select-label">Priority</InputLabel>
              <Select
                disabled={displayIds.length === 0}
                labelId="priority-select-label"
                id="msg-priority-select"
                value={priority}
                label="Priority"
                onChange={OnChangePriority}
              >
                <MenuItem value={"Fun"}>Fun</MenuItem>
                <MenuItem value={"Normal"}>Normal</MenuItem>
                <MenuItem value={"Important"}>Important</MenuItem>
                <MenuItem value={"Urgent"}>Urgent</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="messagecolor-select-label">
                Message display color
              </InputLabel>
              <Select
                disabled={displayIds.length === 0}
                labelId="messagecolor-select-label"
                id="messagecolor-select"
                value={color}
                label="Message display color"
                onChange={(evt) => onChangeMessageColor(evt)}
              >
                <MenuItem value={"blue"}>Blue</MenuItem>
                <MenuItem value={"lightBlue"}>Light Blue</MenuItem>
                <MenuItem value={"yellow"}>Yellow</MenuItem>
                <MenuItem value={"orange"}>Orange</MenuItem>
                <MenuItem value={"red"}>Red</MenuItem>
                <MenuItem value={"purple"}>Purple</MenuItem>
                <MenuItem value={"pink"}>Pink</MenuItem>
                <MenuItem value={"magenta"}>Magenta</MenuItem>
                <MenuItem value={"white"}>White</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="priority-select-label">Text Effect</InputLabel>
              <Select
                disabled={displayIds.length === 0}
                labelId="textEffect-select-label"
                id="msg-textEffect-select"
                value={textEffect}
                label="Text Effect"
                onChange={OnChangeTextEffect}
              >
                <MenuItem value={"NoEffect"}>No Effect</MenuItem>
                <MenuItem value={"GlitterIn"}>Glitter</MenuItem>
                <MenuItem value={"Outline"}>Outline</MenuItem>
                <MenuItem value={"RectangleOver"}>Rectangle Over</MenuItem>
              </Select>
            </FormControl>
            <Button
              disabled={displayIds.length === 0}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Message
            </Button>
          </Box>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}
