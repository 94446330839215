import { Link } from "react-router-dom";

const Home = () => {

  return (
    <main>
      <h1>Welcome RoseGeese!</h1>
    </main>
  );
};

export default Home;
